import React from "react"
import { Link } from "gatsby"
import { Container } from "../components/global"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"

const ThankYouPage = () => (
  <Layout>
    <Container>
      <SEO title="Thank you" />
      <h1>Vielen Dank für Ihre Interesse</h1>
      <p>
        Wir arbeiten an der Entwicklung des WelpenOrganizer und bitten um
        Verständnis, dass der Service derzeit noch nicht zur Verfügung steht.
        Ihre persönlichen Daten werden nicht gespeichert.
      </p>
      <br />
      <Link to="/">Zurück</Link>
    </Container>
  </Layout>
)

export default ThankYouPage
